import { Row, Col, Modal, Form, Input, Select, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";

const AddFrom = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [centerList, setCenterList] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(data);

  const getOrganizations = () => {
    request({
      url: apiPath.getExamBoard,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data, "datadata")
        setCenterList(data.data.data);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getOrganizations();
    if (!data) return;

    form.setFieldsValue({ ...data, examBoard: data.examBoard?._id });
  }, [data]);

  const onCreate = (values) => {
    const { name, examBoard, exam_code, start_date, end_date } = values;
    setLoading(true);

    const payload = {};
    payload.name = name;
    payload.start_date = start_date;
    payload.end_date = end_date;
    payload.exam_code = exam_code;
    payload.examBoard = examBoard;

    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={800}
      title={`${data ? "Update " + section : "Create a New " + section}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Name`}
              name="name"
              rules={[
                { required: true, message: `Please enter the name!` },
                {
                  max: 200,
                  message: "Name should not contain more then 200 characters!",
                },
                {
                  min: 2,
                  message: "Name should contain atleast 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Name`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Exam Code`}
              name="exam_code"
              rules={[
                { required: true, message: "Exam Code must be provided!" },
                {
                  max: 200,
                  message:
                    "Exam Code should not contain more then 200 characters!",
                },
                {
                  min: 2,
                  message: "Exam Code should contain atleast 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Exam Code`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Exam Start Date`}
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Exam Start Date must be provided!",
                },
                () => ({
                  validator(_, value) {
                    if (!value || moment(value).isSameOrAfter(moment(), 'day')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Start date cannot be in the past!")
                    );
                  },
                }),
              ]}
              normalize={(value) => value.trimStart()}
            >
              {/* <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} onChange={(e) => {
                                if (e != null) {
                                    // setEndDate(moment(e._d).format("YYYY-MM-DD"))
                                }
                            }} /> */}
              <Input
                type="date"
                name="start_date"
                placeholder="Enter Exam Start Date"
                min={new Date().toISOString().split("T")[0]}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Exam End Date`}
              name="end_date"
              rules={[
                { required: true, message: "Exam End Date must be provided!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || moment(value).isSameOrAfter(getFieldValue("start_date"))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("End Date cannot be before Start Date!")
                    );
                  },
                }),
              ]}
              normalize={(value) => value.trimStart()}

            >
              <Input
                type="date"
                name="end_date"
                placeholder="Enter Exam End Date"
                min={new Date().toISOString().split("T")[0]}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="category-elt mb-0"
              name="examBoard"
              label="Select Exam Board"
              rules={[{ required: true, message: "Missing Exam Board Selection" }]}
            >
              <Select
                placeholder="Select Exam Board"
                value={data && data.examBoard._id}
              >
                {centerList.length > 0
                  ? centerList.map((item, index) => (
                    <option
                      selected={data && data.examBoard._id === item._id}
                      key={item._id}
                      value={item._id}
                    >
                      <span className="cap">{item.name}</span>
                    </option>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddFrom;
