import { Row, Col, Modal, Form, Input, Image, Select } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import LocationMap from "../User/LocationMap";


const AddFrom = ({ section, api, show, hide, data, refresh }) => {

    const [form] = Form.useForm();
    const { request } = useRequest()
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState([]);
    const [image, setImage] = useState([]);
    const [cityId, setCityId] = useState();
    const [latLong, setlatLong] = useState({ lat: 30.5595, lng: 22.9375 });
    const [userAddress, setUserAddress] = useState(null);
    const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]
    const [editLocation, setEditLocation] = useState(null);
    const handleLocationChange = (value) => {
        setUserAddress(value.address);
        setlatLong({
            lat: parseFloat(value.latitude),
            lng: parseFloat(value.longitude),
        });
        setCityId(value.city);
        console.log(value)
    };

    const handleImage = (data) => {
        setImage(data);
        data.length > 0 ? setFile(data[0].url) : setFile([]);
    }

    const onCreate = (values) => {
        const { name, profile_desc, email, web_url, center_code, concession } = values
        setLoading(true)

        const payload = {}
        payload.name = name;
        payload.web_url = web_url;
        payload.profile_desc = profile_desc
        payload.center_code = center_code
        payload.email = email
        payload.image = image && image.length > 0 ? image[0].url : '';
        payload.latitude = latLong.lat ? latLong.lat : null;
        payload.longitude = latLong.lng ? latLong.lng : null;
        payload.location = userAddress ? userAddress : null;
        payload.city = cityId ? cityId : null;
        payload.concession = concession ? concession : "no";

        request({
            url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    hide()
                    refresh()
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    useEffect(() => {
        if (!data) return;

        form.setFieldsValue({ ...data })
        setFile([data.image])
        if (data.image != undefined) {
            setImage([data.image])
        } else {
            setImage([notfound])
        }

        setEditLocation({
            location: data?.location,
            latitude: data?.latitude,
            longitude: data?.longitude,
          });
    }, [data])


    return (
        <Modal
            open={show}
            width={800}
            title={`${data ? 'Update ' + section : 'Create a New ' + section}`}
            okText="Ok"
            onCancel={hide}
            okButtonProps={{
                form: 'create',
                htmlType: 'submit',
                loading: loading,
            }}
        >
            <Form id="create" form={form} onFinish={onCreate} layout="vertical">
                <Row gutter={[16, 16]}>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Name`} name="name"
                            rules={[
                                { required: true, message: `Please enter the name!` },
                                { max: 100, message: "Name should not contain more then 100 characters!" },
                                { min: 2, message: "Name should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            <Input maxLength={100} autoComplete="off" placeholder={`Enter Name`} />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Profile Description`} name="profile_desc"
                            rules={[
                                { required: true, message: "Profile Description must be provided!" },
                                { max: 100, message: "Profile Description should not contain more then 100 characters!" },
                                { min: 2, message: "Profile Description should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            <Input maxLength={100} autoComplete="off" placeholder={`Enter Profile Description`} />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Website Url`} name="web_url"
                            rules={[
                                { required: true, message: "Website Url must be provided!" },
                                { max: 200, message: "Website Url should not contain more then 200 characters!" },
                                { min: 2, message: "Website Url should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            <Input autoComplete="off" placeholder={`Enter Website Url`} />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Centre Email`} name="email"
                            rules={[
                                {
                                    type: 'email', message: 'Please enter a valid email address!',
                                },
                                {
                                    max: 255, message: 'Email address not more then 255 characters!',
                                },
                                {
                                    required: true,
                                    message: "Please enter your email!",
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder={`Enter Centre Email`} />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Concessions`} name="concession"
                        >
                            <Select name="concession" placeholder="Select Concessions">
                                <option

                                    value="yes"
                                >
                                    Yes
                                </option>
                                <option

                                    value="no"
                                >
                                    No
                                </option>


                            </Select>
                        </Form.Item>
                    </Col>

                    <Row>
                        <Col span={24} md={24}>
                            <Form.Item
                                label="Location (Drag Marker for Selecting Location)"
                                name="location"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select the location!",
                                    },
                                ]}
                            >
                                <LocationMap
                                    className="mt-3"
                                    onChange={handleLocationChange}
                                    editLocation={editLocation}
                                // userData={data && data}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0"
                            label={"Upload Image"} name="image"
                        // rules={[
                        //   {
                        //     validator: (_, value) => {
                        //       if (value !== undefined && value?.length > 0) {
                        //         return Promise.resolve();
                        //       }
                        //       return Promise.reject(new Error('Image is required'));
                        //     },
                        //   }
                        // ]}
                        >
                            <SingleImageUpload value={image} fileType={FileType} imageType={'service'} btnName={'Image'} onChange={(data) => handleImage(data)} />
                            {<p style={{ color: "red" }}>Note:Upload Image of center logo (250*400) .</p>}
                        </Form.Item>
                        {file && file.length > 0 && <div className="mt-2"> <Image width={120} src={file !== "" ? file : notfound}></Image> </div>}
                    </Col>

                </Row>

            </Form>
        </Modal>
    )
}

export default AddFrom;
