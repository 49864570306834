import { Row, Col, Modal, Form, Input, Select, DatePicker, Space, Button } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";


const AddComponent = ({ section, api, show, hide, data, refresh }) => {

    const [form] = Form.useForm();
    const { request } = useRequest()
    const [examList, setExamList] = useState([])
    const [courseList, setCourseList] = useState([])
    const [loading, setLoading] = useState(false)

    const getCourses = () => {
        request({
            url: apiPath.listCourse,
            method: 'GET',
            onSuccess: (data) => {
                setCourseList(data.data.list.docs);
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    };

    const getExams = () => {
        request({
            url: apiPath.listExam,
            method: 'GET',
            onSuccess: (data) => {
                setExamList(data.data.list.docs);
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    };

    useEffect(() => {
        getCourses();
        getExams();
        if (!data) return;

        form.setFieldsValue({ ...data, exam_id: data.exam_id?._id, course_id: data.course_id?._id })
    }, [data])


    const onCreate = (values) => {

        const { name, description, exam_id, course_id, option_values } = values
        setLoading(true)

        const payload = {}
        payload.name = name;
        payload.description = description;
        payload.exam_id = exam_id;
        payload.course_id = course_id;
        payload.option_values = option_values;

        request({
            url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    hide()
                    refresh()
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    return (
        <Modal
            open={show}
            width={1000}
            title={`${data ? 'Update ' + section : 'Create a New ' + section}`}
            okText="Ok"
            onCancel={hide}
            okButtonProps={{
                form: 'create',
                htmlType: 'submit',
                loading: loading,
            }}
        >
            <Form id="create" form={form} initialValues={{ option_values: [null] }} onFinish={onCreate} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.List name="option_values" className="mt-2">
                            {(fields, { add, remove }) => (
                                <>
                                    <Row gutter={[16, 0]}>
                                        {fields.map((field, index) => (
                                            <Col span={24} sm={24} md={24} lg={24} xl={24} xxl={24} key={field.key}>
                                                <Space align="baseline" className="gap-cls">
                                                    <div className="" style={{ display: "flex", gap: "8px" }}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'component_code']} // Use array syntax to specify nested field names
                                                            label={`Option Component Value 1 (${index + 1})`}
                                                            rules={[{ required: true, message: 'Please enter syllabus option value' }]}
                                                            normalize={value => value.trimStart()}
                                                            className="qty-cls w-100 customRowDesign"
                                                        >
                                                            <Input autoComplete="off" placeholder="Enter Syllabus Value 1" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'info']} // Specify the second value field name
                                                            label={`Syllabus Option Value 2 (${index + 1})`}
                                                            rules={[{ required: true, message: 'Please enter syllabus option value' }]}
                                                            normalize={value => value.trimStart()}
                                                            className="qty-cls w-100 customRowDesign"
                                                        >
                                                            <Input autoComplete="off" placeholder="Enter Syllabus Value 2" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'option_code']} // Use array syntax to specify nested field names
                                                            label={`Syllabus Option Value 1 (${index + 1})`}
                                                            rules={[{ required: true, message: 'Please enter syllabus option value' }]}
                                                            normalize={value => value.trimStart()}
                                                            className="qty-cls w-100 customRowDesign"
                                                        >
                                                            <Input autoComplete="off" placeholder="Enter Syllabus Value 1" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'info']} // Specify the second value field name
                                                            label={`Syllabus Option Value 2 (${index + 1})`}
                                                            rules={[{ required: true, message: 'Please enter syllabus option value' }]}
                                                            normalize={value => value.trimStart()}
                                                            className="qty-cls w-100 customRowDesign"
                                                        >
                                                            <Input autoComplete="off" placeholder="Enter Syllabus Value 2" />
                                                        </Form.Item>

                                                        {index > 0 && ( // Only show remove button if there's more than one item
                                                            <div className="minus-wrap" style={{ marginTop: "22px" }}>
                                                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: "8px", margin: '0' }} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </Space>
                                            </Col>
                                        ))}
                                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                            <Form.Item label="Add Values" className="mt-2">
                                                <Button onClick={() => add()} block icon={<PlusOutlined />}>Add</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
}

export default AddComponent;

