
import { Menu, Button, Modal, Skeleton } from "antd";
import { useState, useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { QuestionCircleFilled } from '@ant-design/icons';
import Logo from "../../assets/images/favicon.png";
export const menuItems = [
  {
    key: "Dashboard",
    path: "/dashboard",
    icon: "fas fa-tachometer-alt",
    label: "Dashboard"
  },
  {
    key: "GuardianSection",
    path: "/guardian",
    icon: "fas fa-user",
    label: "Guardian Manager"
  },
  {
    key: "StudentSection",
    path: "/student",
    icon: "fas fa-user",
    label: "Student Manager"
  },
  {
    key: "CenterSection",
    path: "/centre",
    icon: "fas fa-home",
    label: "Centre Manager"
  },
  {
    key: "ExamConductSection",
    path: "/exam-conduct",
    icon: "fas fa-book",
    label: "Exam Conduct Manager"
  },
  
  {
    key: "EventSection",
    path: "/event",
    icon: "fas fa-globe",
    label: "Event Manager"
  },
  {
    key: "PreparationSection",
    path: "/preparation",
    icon: "fas fa-globe",
    label: "Preparation Manager"
  },
  {
    key: "ExamBoard",
    path: "/exam-board",
    icon: "fas fa-book",
    label: "Exam Board Manager"
  },
  {
    key: "CourseSection",
    path: "/course",
    icon: "fas fa-book",
    label: "Level Manager"
  },

  {
    key: "ExamSection",
    path: "/exam",
    icon: "fas fa-pen",
    label: "Exam Manager"
  },
  {
    key: "SyllabusSection",
    path: "/syllabus",
    icon: "fas fa-book",
    label: "Syllabus Manager"
  },
  {
    key: "SyllabusOptionSection",
    path: "/syllabusOption",
    icon: "fas fa-book",
    label: "Syllabus Component Manager"
  },

  {
    key: "OrderManager",
    path: "/booking-manager",
    icon: "fas fa-book",
    label: "Booking Manager"
  },
  {
    key: "NotificationSection",
    path: "/notification-manager",
    icon: "fas fa-book",
    label: "Notification Manager"
  },
  {
    key: "ContentSection",
    path: "/content",
    icon: "fa fa-file",
    label: "Content Manager"
  },
  {
    key: "EmailSection",
    path: "/email-template",
    icon: "fas fa-envelope",
    label: "Email Template Manager"
  },
  // {
  //   key: "Setting",
  //   path: "/setting",
  //   icon: "fas fa-cog",
  //   label: "Setting Manager"
  // },
]


function Sidenav({ color }) {

  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { userProfile, logout, setUserProfile } = useContext(AuthContext)
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState('vertical');

  const showDeleteConfirm = (record) => {
    setTimeout(() => {
      confirm({
        okText: 'Logout',
        okType: 'danger',
        icon: <QuestionCircleFilled />,
        cancelText: 'Cancel',
        content: <Button >Are you sure you want to logout ?</Button>,
        onOk() {
          logout();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const isActiveLink = (pattern, pathname) => {
    const regexPattern = new RegExp(`^${pattern.replace('*', '.*')}$`);
    return regexPattern.test(pathname);
  };

  const isVehicleDropdownOpen = isActiveLink('/vehicle/*', pathname);
  const isProductDropdownOpen = isActiveLink('/product/*', pathname);
  const isCategoryDropdownOpen = isActiveLink('/category/*', pathname);
  const isUserDropdownOpen = isActiveLink('/user/*', pathname);

  const renderTitle = (item) => {
    return (
      <>
        <span className="icon"><i className={item.icon}></i></span>
        <span className="label">{item.label}</span>
      </>
    )
  }

  useEffect(() => {
    setLoading(true)
    if (!userProfile) return
    if (userProfile.type == 'Admin') {
      setMenuLinks(menuItems)
      setLoading(false)
      return
    }
    const newArray = menuItems.filter(item => {
      if (item.children) {
        item.children = item.children.filter(child => userProfile.permission?.hasOwnProperty(child.key));
      } else {
        return userProfile.permission?.hasOwnProperty(item.key)
      }
      return item
    });

    const links = newArray.filter(item => {
      if (item?.children?.length) {
        return true
      } else if (!item?.children) {
        return true
      } else {
        return false
      }

    })

    setMenuLinks(links)
    setLoading(false)

  }, [userProfile])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode('inline');
      } else {
        setMenuMode('vertical');
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="brand w-100">
        <NavLink to="" className="imgOuter">
          <img className="" src={Logo} alt="" />
        </NavLink>
      </div>
      {
        loading ? [1, 2, 3, 4, 5, 6].map(item => <Skeleton active key={item} />)
          :
          <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
            {menuLinks.map(item => {
              if (item.children) {
                return (
                  <Menu.SubMenu key={item.key} title={<>
                    <span className="icon"><i className={item.icon}></i></span>
                    <span className="label">{item.label}</span>
                  </>}>
                    {item.children.map(child => (
                      <Menu.Item key={child.key}>
                        <NavLink to={child.path}>{child.label}</NavLink>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              }

              return (
                <Menu.Item key={item.key}>
                  <NavLink to={item.path}>
                    {renderTitle(item)}
                  </NavLink>
                </Menu.Item>
              );
            })}


            <Menu.Item onClick={showDeleteConfirm}>
              <NavLink to={'/'}>
                <>
                  <span className="icon"><i className="fas fa-sign-out-alt"></i></span>
                  <span className="label">Logout</span>
                </>
              </NavLink>
            </Menu.Item>

          </Menu>
      }

    </>
  );

}

export default Sidenav;
