import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Tag,
  Select,
  Tooltip,
  message,
  InputNumber,
  Image,
} from "antd";
import React, { useState, useEffect } from "react";
import ShowTotal from "../../components/ShowTotal";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { ExclamationCircleOutlined, UndoOutlined } from "@ant-design/icons";
import apiPath from "../../constants/apiPath";
import moment from "moment";
import useDebounce from "../../hooks/useDebounce";
import ConfirmationBox from "../../components/ConfirmationBox";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import { shortLang, longLang } from "../../config/language";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import ImportForm from "../../components/ImportForm";
import AddFrom from "./Add";
import { useNavigate, useParams } from "react-router";
import AddEditSyllabusOpt from "../Center/Component/AddEditSyllabusOpt";

const Search = Input.Search;

function ViewExamConduct() {
  const sectionName = "View Exam Conduct";

  const routeName = "examconduct";

  const api = {
    status: apiPath.statusExamConduct,
    addEdit: apiPath.addEditExamConduct,
    list: apiPath.ViewExamConduct,
  };

  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState("");
  const [importVisible, setImportVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const { showConfirm } = ConfirmationBox();
  const params = useParams();
  const [show, setShow] = useState(false);
  const [syllabusOptData, setSyllabusOptData] = useState({});
  const [syallbus, setsyallbus] = useState([]);
  const [filter, setFilter] = useState({
    syallbus: undefined,
  });
  const columns = [
    {
      title: "Exam Name",
      dataIndex: "exam_name",
      key: "exam_name",
      render: (_, { examId }) => {
        return <span className="cap">{examId?.name ? examId?.name : "-"}</span>;
      },
      sorter: (a, b) => {
        let nameA = a?.examId?.name.toLowerCase();
        let nameB = b?.examId?.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Syallbus",
      dataIndex: "syallbus_details",
      key: "syallbus_details",
      render: (_, { syallbus_details }) => {
        return (
          <span className="cap">
            {syallbus_details?.name
              ? syallbus_details?.name
              : "-"}
          </span>
        );
      },
      sorter: (a, b) => {
        let nameA = a?.syallbus_details?.name.toLowerCase();
        let nameB = b?.syallbus_details?.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Syallbus Option",
      dataIndex: "syallbus_option_details",
      key: "syallbus_option_details",
      render: (_, { syallbus_option_details }) => {
        return (
          <span className="cap">
            {syallbus_option_details?.option_code
              ? syallbus_option_details?.option_code
              : "-"}
          </span>
        );
      },
      sorter: (a, b) => {
        let nameA = a?.syallbus_option_details?.option_code.toLowerCase();
        let nameB = b?.syallbus_option_details?.option_code.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Exam Center",
      dataIndex: "center_id",
      key: "center_id",
      render: (_, { centerId }) => {
        return <span className="cap">{centerId ? centerId?.name : "-"}</span>;
      },
      sorter: (a, b) => {
        let nameA = a.centerId?.name.toLowerCase();
        let nameB = b.centerId?.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Normal Fee",
      dataIndex: "normal",
      key: "normal",
      render: (_, { normal }) => {
        return (
          <>
            <div className="pricetcolumn">
              <span className="cap">
                {normal ? `booking Fee : ${normal?.bookingFee}` : "0"}
              </span>
              <span className="cap">
                {normal ? `Booknow Fee : ${normal?.bookingNow}` : "0"}
              </span>
              <span className="cap">
                {normal ? `payFast Fee : ${normal?.payFast}` : "0"}
              </span>
              <span className="cap">
                {normal ? `youGet Fee: ${normal?.youGet}` : "0"}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Late Fee",
      dataIndex: "late",
      key: "late",
      render: (_, { late }) => {
        return (
          <>
            <div className="pricetcolumn">
              <span className="cap">
                {late ? `booking Fee : ${late?.bookingFee}` : "0"}
              </span>
              <span className="cap">
                {late ? `Booknow Fee : ${late?.bookingNow}` : "0"}
              </span>
              <span className="cap">
                {late ? `payFast Fee : ${late?.payFast}` : "0"}
              </span>
              <span className="cap">
                {late ? `youGet Fee : ${late?.youGet}` : "0"}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Super Late Fee",
      dataIndex: "superLate",
      key: "superLate",
      render: (_, { superLate }) => {
        return (
          <>
            <div className="pricetcolumn">
              <span className="cap">
                {superLate ? `booking Fee : ${superLate?.bookingFee}` : "0"}
              </span>
              <span className="cap">
                {superLate ? `Booknow Fee : ${superLate?.bookingNow}` : "0"}
              </span>
              <span className="cap">
                {superLate ? `payFast Fee: ${superLate?.payFast}` : "0"}
              </span>
              <span className="cap">
                {superLate ? `youGet Fee: ${superLate?.youGet}` : "0"}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "totalSeat",
      dataIndex: "totalSeat",
      key: "totalSeat",
      render: (_, { totalSeat }) => {
        return <span className="cap">{totalSeat ? totalSeat : "0"}</span>;
      },
    },
    {
      title: "totalBookedSeat",
      dataIndex: "totalBookedSeat",
      key: "totalBookedSeat",
      render: (_, { totalBookedSeat }) => {
        return (
          <span className="cap">{totalBookedSeat ? totalBookedSeat : "0"}</span>
        );
      },
    },
    {
      title: "availableSeat",
      dataIndex: "availableSeat",
      key: "availableSeat",
      render: (_, { availableSeat }) => {
        return (
          <span className="cap">{availableSeat ? availableSeat : "0"}</span>
        );
      },
    },

    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
      sorter: (a, b) => {
        let nameA = a.created_at;
        let nameB = b.created_at;
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Action",
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={"Update " + sectionName}
              color={"purple"}
              key={"update" + routeName}
            >
              <Button
                title="Edit"
                onClick={() => {
                  setShow(true);
                  setSyllabusOptData({
                    courseName:  record?.course?.name ?? record?.course?.name,                  
                    syllabusName:  record?.syallbus_details?.name  ?? "",
                    syllabusOptName: record?.syllabusOpt?.option_code || "None",
                    examId: record?.examId ? record?.examId?._id : "" ,
                    examConductsId: record?.examConduct || "",
                    centerId:  record?.centerId ? record?.centerId?._id  : "",
                    courseId:  record?.course || "",
                    syllabusId:  record?.syallbus_option_details?.syllabus_id ||  "",
                    syllabusOptionId: record?.syallbus_option_details?._id ?? record?.syallbus_option_details?._id,
                  });
                }}
              >
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    fetchData(pagination);
    getOrganizations()
  }, [refresh, debouncedSearchText,filter, params]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const id = params.id ?? null;

    const queryString = Object.entries(filter)
    .filter(([_, v]) => v)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
    request({
      url:
        api.list +
        "/" +
        id +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&limit=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${
          queryString ? `&${queryString} ` : ""
        }`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data.list.docs);
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.list.totalDocs,
        }));
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const getOrganizations = () => {
    request({
      url: apiPath.getAllSyallbus,
      method: "GET",
      onSuccess: (data) => {
        setsyallbus(data.data?.list);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };
  const onChange = (key, value) => { 
    setFilter((prev) => ({ ...prev, [key]: value }));
};
  return (
    <>
      <div className="tabled categoryService">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " Management"}
              extra={
                <>
                 <div className="button_group justify-content-end w-100">
                 <div className="role-wrap">
                <Select
                 
                  last20Years
                  width="110px"
                  placeholder={("Syllabus")}
                  showSearch
                  value={filter.syallbus}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={syallbus.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  onChange={(value) => onChange("syallbus", value)}
                />
              </div>

              <Button
                onClick={() => {
                  setFilter({
                    syallbus: undefined,
                  });
               
                }}
                type="primary"
                icon={<UndoOutlined/>}
              >
                {("Reset")}
              </Button>
              </div>
                </>
              }
            >
              <h4 className="text-right mb-1">
                {pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}
              </h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    current: pagination?.current,
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && (
        <AddFrom
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
     {show && <AddEditSyllabusOpt
        show={show}
        hide={() => {
          setSyllabusOptData({})
          setShow(false)
        } }
        data={syllabusOptData}
        refresh={()=> setRefresh(prev => !prev)}
      /> }
    </>
  );
}

export default ViewExamConduct;
