import { Row, Col, Card, Table, Button, Modal, Form, Input, Tag, Select, Tooltip, message, InputNumber, Image } from "antd";
import React, { useState, useEffect } from "react";
import ShowTotal from "../../components/ShowTotal";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { ExclamationCircleOutlined, UndoOutlined } from '@ant-design/icons';
import apiPath from "../../constants/apiPath";
import moment from "moment";
import useDebounce from "../../hooks/useDebounce";
import ConfirmationBox from "../../components/ConfirmationBox";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import { shortLang, longLang } from "../../config/language";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import ImportForm from "../../components/ImportForm";
import AddFrom from "./Add";
import { useNavigate } from "react-router";

const Search = Input.Search;

function Index() {

  const sectionName = "Exam Conduct";
  const routeName = "examconduct";

  const api = {
    status: apiPath.statusExamConduct,
    addEdit: apiPath.addEditExamConduct,
    list: apiPath.listExamConduct
  }

  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState('');
  const [importVisible, setImportVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const { showConfirm } = ConfirmationBox();
  const navigate = useNavigate()
  const [exams,setExams] = useState([])
  const [centres,setCentres] = useState([])
  const [filter, setFilter] = useState({
    centre_id: undefined,
    exam_id: undefined,
  });

  const columns = [
    {
      title: "S No",
      dataIndex: "s_no",
      key: "s_no",
      render: (_, { }, index) => <span>{index + 1}</span>
    },
    {
      title: "Exam Name",
      dataIndex: "exam_name",
      key: "exam_name",
      render: (_, { exam_id }) => {
        return (
          <span className="cap">{exam_id?.name ? exam_id?.name : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Exam Center",
      dataIndex: "center_id",
      key: "center_id",
      render: (_, { center_id }) => {
        return (
          <span className="cap">{center_id ? center_id.name : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.center_id?.name.toLowerCase();
        let nameB = b.center_id?.name.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Exam Code",
      dataIndex: "exam_code",
      key: "exam_code",
      render: (_, { exam_id }) => {
        return (
          <span className="cap">{exam_id?.exam_code ? exam_id?.exam_code?.toUpperCase() : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.exam_id?.exam_code.toLowerCase();
        let nameB = b.exam_id?.exam_code.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: "Exam Start Date",
      key: "exam_start_date",
      dataIndex: "exam_start_date",
      render: (_, { exam_id }) => {
        return (
          moment(exam_id?.start_date).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.exam_id?.start_date;
        let nameB = b.exam_id?.start_date;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Exam End date",
      key: "exam_end_date",
      dataIndex: "exam_end_date",
      render: (_, { exam_id }) => {
        return (
          moment(exam_id?.end_date).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.exam_id?.end_date;
        let nameB = b.exam_id?.end_date;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Booking  Start Date",
      key: "booking_start_date",
      dataIndex: "booking_start_date",
      render: (_, { booking_start_date }) => {
        return (
          moment(booking_start_date).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.booking_end_date;
        let nameB = b.booking_end_date;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Booking End date",
      key: "booking_end_date",
      dataIndex: "booking_end_date",
      render: (_, { booking_end_date }) => {
        return (
          moment(booking_end_date).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.booking_end_date;
        let nameB = b.booking_end_date;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.created_at;
        let nameB = b.created_at;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={"Update " + sectionName} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>
            <Tooltip title={"View " + sectionName} color={"purple"} key={"view" + routeName}><Button onClick={(e) => {
              navigate(`/exam-conduct/view/${record._id}`, { state: { additionalData: selected } });
            }}><i className="fa fa-light fa-eye"></i></Button></Tooltip>
          </>
        );
      },
    },
  ];

  const getOrganizations = () => {
    request({
      url: apiPath.allExam,
      method: "GET",
      onSuccess: (data) => {
        setExams(data.data?.list);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const getCentre = () => {
    request({
      url: apiPath.allCentre,
      method: "GET",
      onSuccess: (data) => {
        setCentres(data.data?.list);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
    getOrganizations()
    getCentre()
  }, [refresh,filter, debouncedSearchText])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    const queryString = Object.entries(filter)
    .filter(([_, v]) => v)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
    request({
      url: api.list + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}${
        queryString ? `&${queryString} ` : ""
      }`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onChange = (key, value) => { 
      setFilter((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <div className="tabled categoryService">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " Management"}
              extra={
                <>
                  <Search
                    allowClear
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    placeholder="Search By Name"
                  />
                
            
              <div className="button_group justify-content-end w-100">
              <div className="role-wrap">
                <Select
                 
                  last20Years
                  width="110px"
                  placeholder={("Centre")}
                  showSearch
                  value={filter.centre_id}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={centres?.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  onChange={(value) => onChange("centre_id", value)}
                />
              </div>
                 <div className="role-wrap">
                <Select
                 
                  last20Years
                  width="110px"
                  placeholder={("Exam")}
                  showSearch
                  value={filter.exam_id}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={exams.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  onChange={(value) => onChange("exam_id", value)}
                />
              </div>

              <Button
                onClick={() => {
                  setFilter({
                    centre_id: undefined,
                    exam_id: undefined,
                  });
                  setSearchText("");
                }}
                type="primary"
                icon={<UndoOutlined/>}
              >
                {("Reset")}
              </Button>
                    {/* <Button onClick={(e) => { navigate('/add-exam'); }}>Add {sectionName}</Button> */}
                    <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add {sectionName}</Button>
                  </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{current: pagination?.current, defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom section={sectionName} api={api} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}

    </>
  );
}

export default Index;
