import { Row, Col, Card, Table, Button, Modal, Form, Input, Tag, Select, Tooltip, message, InputNumber, Image } from "antd";
import React, { useState, useEffect } from "react";
import ShowTotal from "../../components/ShowTotal";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import apiPath from "../../constants/apiPath";
import moment from "moment";
import useDebounce from "../../hooks/useDebounce";
import ConfirmationBox from "../../components/ConfirmationBox";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import { shortLang, longLang } from "../../config/language";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import ImportForm from "../../components/ImportForm";
import AddFrom from "./Add";
import { useNavigate } from "react-router";

const Search = Input.Search;

function Index() {

  const sectionName = "Event";
  const routeName = "event";

  const api = {
    status: apiPath.statusEvent,
    addEdit: apiPath.addEditEvent,
    list: apiPath.listEvent,
  }

  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState('');
  const [importVisible, setImportVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const { showConfirm } = ConfirmationBox();
  const navigate = useNavigate();
  const [rowFilter, setRowFilter] = useState({});


  const columns = [
    {
      title: "No",
      dataIndex: "s_no",
      key: "s_no",
      render: (_, { }, index) => <span>{index + 1}</span>
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return (
          <span className="cap">{name ? name : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.name;
        let nameB = b.name;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      render: (_, { mode }) => {
        return (
          <span className="cap">{mode ? mode : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.mode;
        let nameB = b.mode;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Centre",
      dataIndex: "center_id",
      key: "center_id",
      render: (_, { center_id }) => {
        return (
          <span className="cap">{center_id ? center_id.name : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.center_id.name;
        let nameB = b.center_id.name;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Event Code",
      dataIndex: "event_code",
      key: "event_code",
      render: (_, { event_code }) => {
        return (
          <span className="cap">{event_code ? event_code?.toUpperCase() : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.event_code;
        let nameB = b.event_code;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Booking Fees",
      dataIndex: "booking_fees",
      key: "booking_fees",
      render: (_, { booking_fees, is_free }) => {
        return (
          <span className="cap">
            {is_free ? "Free" : booking_fees}
          </span>
        );
      },

    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: "Start Date",
      key: "start_date",
      dataIndex: "start_date",
      render: (_, { start_date }) => {
        return (
          moment(start_date).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.start_date;
        let nameB = b.start_date;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "End date",
      key: "end_date",
      dataIndex: "end_date",
      render: (_, { end_date }) => {
        return (
          moment(end_date).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.end_date;
        let nameB = b.end_date;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.created_at;
        let nameB = b.created_at;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={"Update " + sectionName} color={"purple"} key={"update" + routeName}>
              <Button title="Edit"
                onClick={(e) => {
                  navigate(`/event/edit/${record._id}`, { state: { additionalData: selected } });
                }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>

            <Tooltip title={"View " + sectionName} color={"purple"} key={"view" + routeName}><Button onClick={(e) => {
              navigate(`/event/view/${record._id}`, { state: { additionalData: selected } });
            }}><i className="fa fa-light fa-eye"></i></Button></Tooltip>
          </>
        );
      },
    },

  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive =  filters  ?  filters.is_active : rowFilter ? rowFilter.is_active : null
    request({
      url: api.list + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        console.log(data, "line 26")
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current:  data?.data?.list?.page, pageSize : data?.data?.list?.limit,  total: data?.data?.list?.totalDocs }))

      },
      onError: (error) => {
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1, pageSize : pagination.pageSize ?? 10 })

  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
    setRowFilter(filters)
  }


  return (
    <>
      <div className="tabled categoryService">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " Management"}
              extra={
                <>
                  <Search
                    allowClear
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    placeholder="Search By Name, Email"
                  />
                  <div className="button_group justify-content-end w-100">
                    {/* <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add {sectionName}</Button> */}
                    <Button onClick={(e) => { navigate(`/event/add`); }}>Add {sectionName}</Button>
                  </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{current: pagination?.current, defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom section={sectionName} api={api} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}
    </>
  );
}

export default Index;
