export const PRICE = "R";

export const OTPMessage = "Enter OTP that has been sent to your mobile number.";

export const bannerType = [
    { id: 'normal', value: "Normal" },
    { id: 'category', value: "Category" },
    { id: 'advertise', value: "Advertise" }
    // {id : 'offer', value : "Offer"}
]

export const eventType = [
    { label: 'Work Shop', value: "work_shop" },
    { label: 'Mock Exam', value: "mock_exam" },
    { label: 'General Event', value: "general_event" },
    { label: 'Seminar', value: "seminar" },
    { label: 'Conference', value: "conference" },
    { label: 'Class', value: "class" },
    { label: 'Expo', value: "expo" },
]

export const eventCategory = [
    { label: 'Event', value: "event" },
    { label: 'Exam Preparations', value: "exam_preparation" },
]

export const eventMode = [
    { label: 'Onsite', value: "onsite" },
    { label: 'Online', value: "online" },

]