import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Space,
  Button,
} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const Option = { Select }
const AddFrom = ({ section, api, show, hide, data, refresh }) => {

  console.log(data, "line21")
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [examList, setExamList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCourses = () => {
    request({
      url: apiPath.getCourseAll,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data)
        setCourseList(data.data.list);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const getExams = () => {
    request({
      url: apiPath.listExam,
      method: "GET",
      onSuccess: (data) => {
        setExamList(data.data.list.docs);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getCourses();
    getExams();
    if (!data) return;

    form.setFieldsValue({
      ...data,
      exam_id: data.exam_id?._id,
      course_id: data.course_id?._id,
    });
  }, [data]);

  const onCreate = (values) => {
    const {
      name,
      description,
      exam_id,
      course_id,
      option_values,
      syllabus_code,
    } = values;
    setLoading(true);

    const payload = {};
    payload.name = name;
    payload.description = description;
    payload.exam_id = exam_id;
    payload.course_id = course_id;
    payload.option_values = option_values;
    payload.syllabus_code = syllabus_code;

    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={800}
      title={`${data ? "Update " + section : "Create a New " + section}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <Form
        id="create"
        form={form}
        initialValues={{ option_values: [null] }}
        onFinish={onCreate}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Subject Name`}
              name="name"
              rules={[
                {
                  required: true,
                  message: `Please enter the syllabus name!`,
                },
                {
                  max: 200,
                  message:
                    "Syllabus Name should not contain more then 200 characters!",
                },
                {
                  min: 2,
                  message: "Syllabus Name should contain atleast 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Syllabus Name`} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Subject Code`}
              name="syllabus_code"
              rules={[
                {
                  required: true,
                  message: `Please enter the syllabus Code!`,
                },
                {
                  max: 7,
                  message:
                    "Syllabus Code should not contain more then 7 characters!",
                },
                {
                  min: 2,
                  message:
                    "Syllabus Name should contain at least 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Syllabus Code`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Subject Description`}
              name="description"
              rules={[
                {
                  required: true,
                  message: `Please enter the syllabus description!`,
                },
                {
                  max: 500,
                  message:
                    "Syllabus description should not contain more then 500 characters!",
                },
                {
                  min: 2,
                  message:
                    "Syllabus description should contain atleast 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Syllabus Name`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="category-elt mb-0"
              name="exam_id"
              label="Select Exam"
              rules={[{ required: true, message: "Missing Exam Selection" }]}
            >
              <Select placeholder="Select Exam">
                {examList.length > 0
                  ? examList.map((item, index) => (
                    <option
                      selected={data && data.exam_id._id === item._id}
                      key={item._id}
                      value={item._id}
                    >
                      <span className="cap">{item.name}</span>
                    </option>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="category-elt mb-0"
              name="course_id"
              label="Select Level"
              rules={[{ required: true, message: "Missing Level Selection" }]}
            >
              <Select placeholder="Select Level">
                {courseList.length > 0
                  ? courseList.map((item, index) => (
                    <Option
                      key={item._id}
                      value={item._id}
                    >
                      <span className="cap">{item.name}</span>
                    </Option>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Row gutter={[12, 0]}>
            <Col span={24}>
              {/* <Form.List name="options" className="mt-2">
                                {(fields, { add, remove }, { form }) => (
                                    <>
                                        <Row gutter={[12, 0]}>
                                            {fields.map((field, index) => (
                                                <Col span={24} sm={24} md={24} lg={24} xl={24} xxl={6}>
                                                    <div key={field.key}>
                                                        <Space key={field.key} align="baseline" className="gap-cls">
                                                            <div className="" style={{ display: "flex", gap: "8px" }}>
                                                            
                                                                <Form.Item className="qty-cls w-100 customRowDesign"
                                                                    {...field}
                                                                    name={[field.name]}
                                                                    label={"Syllabus Option Values (" + (index + 1) + ")"}
                                                                    rules={[{ required: true, message: 'Please enter syllabus option value' }]}
                                                                    normalize={value => value.trimStart()}
                                                                >
                                                                    <Input autoComplete="off" placeholder="Enter Syllabus Value" />
                                                                </Form.Item>
                                                            
                                                                <Form.Item className="qty-cls w-100 customRowDesign"
                                                                    {...field}
                                                                    name={[field.name]}
                                                                    label={"Syllabus Option Values (" + (index + 1) + ")"}
                                                                    rules={[{ required: true, message: 'Please enter syllabus option value' }]}
                                                                    normalize={value => value.trimStart()}
                                                                >
                                                                    <Input autoComplete="off" placeholder="Enter Syllabus Value" />
                                                                </Form.Item>
                                                            
                                                                {index > 0 ?
                                                                    <div className="minus-wrap" style={{ marginTop: "22px" }}>
                                                                        <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: "8px", margin: '0' }} />
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                        </Space>
                                                    </div>
                                                </Col>
                                            ))}
                                            <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                                <Form.Item label="Add Values" className="mt-2">
                                                    <Button onClick={() => add()} block icon={<PlusOutlined AddFrom/>}></Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Form.List> */}

              <Form.List name="option_values" className="mt-2">
                {(fields, { add, remove }) => (
                  <>
                    <Row gutter={[12, 0]}>
                      {fields.map((field, index) => (
                        <Col
                          span={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={12}
                          key={field.key}
                        >
                          <Space align="baseline" className="gap-cls">
                            <div style={{ display: "flex", gap: "8px", width: "100%" }}>
                              {/* <Form.Item
                                {...field}
                                name={[field.name, "info"]} // Specify the second value field name
                                label={`Syllabus Option info ${index + 1}`}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter syllabus option info",
                                  },
                                ]}
                                normalize={(value) => value.trimStart()}
                                className="qty-cls w-100 customRowDesign"
                              >
                                <Input
                                  autoComplete="off"
                                  placeholder="Enter Syllabus info"
                                />
                              </Form.Item> */}

                              <Form.Item
                                {...field}
                                name={[field.name, "option_code"]} // Use array syntax to specify nested field names
                                label={`Subject Option code ${index + 1}`}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter syllabus option code",
                                  },
                                ]}
                                normalize={(value) => value.trimStart()}
                                className="qty-cls w-100 customRowDesign"
                              >
                                <Input
                                  autoComplete="off"
                                  placeholder="Enter Syllabus option code"
                                />
                              </Form.Item>

                              {index > 0 && ( // Only show remove button if there's more than one item
                                <div
                                  className="minus-wrap"
                                  style={{ marginTop: "22px" }}
                                >
                                  <MinusCircleOutlined
                                    onClick={() => remove(field.name)}
                                    style={{
                                      borderRadius: "8px",
                                      margin: "0",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </Space>
                        </Col>
                      ))}
                      <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                        <Form.Item label=" " className="mt-2">
                          <Button
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddFrom;
