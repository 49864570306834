import { Row, Col, Card, Table, Button, Input, Tag, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import ShowTotal from "../../components/ShowTotal";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";
import useDebounce from "../../hooks/useDebounce";
import ConfirmationBox from "../../components/ConfirmationBox";
import ConfigureDateForm from "./ConfigureDateForm";
import { useNavigate, useParams } from "react-router";

const Search = Input.Search;

function Index() {

    const sectionName = "Exam";
    const routeName = "exam";

    const api = {
        status: apiPath.statusExam,
        addEdit: apiPath.addEditExamConduct,
        list: apiPath.listAllExams
    }

    const { request } = useRequest()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState();
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce(searchText, 300);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const { showConfirm } = ConfirmationBox();
    const navigate = useNavigate();
    const params = useParams()

    const columns = [
        {
            title: "S No",
            dataIndex: "s_no",
            key: "s_no",
            width: 100,
            render: (_, { }, index) => <span>{index + 1}</span>
        },
        {
            title: "Exam",
            dataIndex: "exam",
            key: "exam",
            width: 300,
            render: (_, { name, start_date, end_date }) => {
                return (
                    <div>
                        <span className="cap">{name ? name : '-'}</span><br />
                        <div className="cap"><b>Exam Date:</b> <span style={{ fontSize: 11 }}>{start_date}{" - "}{end_date}</span></div>
                    </div>
                )
            },
            sorter: (a, b) => {
                let nameA = a.name.toLowerCase();
                let nameB = b.name.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            },
        },
        {
            title: "Booking Start Date",
            dataIndex: "booking_info",
            key: "booking_info",
            render: (_, { booking_info }) => {
                return (
                    booking_info?.booking_start_date ? moment(booking_info?.booking_start_date).format("DD/MM/YYYY") : "-"
                )
            }
        },
        {
            title: "Booking End Date",
            dataIndex: "booking_info",
            key: "booking_info",
            render: (_, { booking_info }) => {
                return (
                    booking_info?.booking_end_date ? moment(booking_info?.booking_end_date).format("DD/MM/YYYY") : "-"
                )
            }
        },
        {
            title: "Late Booking Till",
            dataIndex: "late_booking",
            key: "late_booking",
            render: (_, { booking_info }) => {
                return (
                    booking_info?.booking_late_date ? moment(booking_info.booking_late_date).format("DD/MM/YYYY") : "-"
                )
            }
        },
        {
            title: "Super Late Booking Till",
            dataIndex: "booking_super_late_date",
            key: "booking_super_late_date",
            render: (_, { booking_info }) => {
                return (
                    booking_info?.booking_super_late_date ? moment(booking_info.booking_super_late_date).format("DD/MM/YYYY") : "-"
                )
            }
        },
        {
            title: "Status",
            key: "is_active",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],
            render: (_, { is_active, _id }) => {
                let color = is_active ? 'green' : 'red';
                return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
            },
        },
        {
            title: "Action",
            fixed: 'right',
            render: (_, record) => {
                return (
                    <>
                        <Tooltip title={"Booking Dates"} color={"purple"} key={"update" + routeName}>
                            <Button onClick={() => {
                                setSelected(record)
                                setVisible(true)
                            }}>
                                <i className="fa fa-solid fa-plus"></i>
                            </Button>
                        </Tooltip>
                        <Tooltip title={""} color={"purple"} key={"update" + routeName}>
                            <Button onClick={() => {
                                navigate(`${record?._id}/add`)
                            }}>
                                <i className="fa fa-light fa-pen"></i>
                            </Button>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        setLoading(true)
        fetchData(pagination)
    }, [refresh, debouncedSearchText])


    const fetchData = (pagination, filters) => {
        const filterActive = filters ? filters.is_active : null
        request({
            url: api.list + "/" + params.id + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
            method: 'GET',
            onSuccess: (data) => {
                setLoading(false)
                setList(data.data.list.docs)
                setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
            },
            onError: (error) => {
                setLoading(false)
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    const onSearch = (e) => {
        setSearchText(e.target.value)
        setPagination({ current: 1 })
    };

    const handleChange = (pagination, filters) => {
        fetchData(pagination, filters);
    }

    return (
        <>
            <div className="tabled categoryService">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title={sectionName + " Listing"}
                            extra={
                                <>
                                    <Search
                                        allowClear
                                        size="large"
                                        onChange={onSearch}
                                        value={searchText}
                                        onPressEnter={onSearch}
                                        placeholder="Search By Name"
                                    />
                                    <div className="button_group justify-content-end w-100">
                                        {/* <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add {sectionName}</Button> */}
                                        {/* <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i class="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Export</Button> */}
                                        {/* <Button title="Import" onClick={(e) => { setImportVisible(true); setSearchText(''); }}><i class="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;Import</Button> */}
                                    </div>
                                </>
                            }
                        >
                            <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
                            <div className="table-responsive customPagination">
                                <Table
                                    loading={loading}
                                    columns={columns}
                                    dataSource={list}
                                    pagination={{current: pagination?.current, defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                                    onChange={handleChange}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            {visible && <ConfigureDateForm section={sectionName} api={api} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
            {/* {addVisible && <AddBooking section={sectionName} api={api} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />} */}
        </>
    );
}

export default Index;
