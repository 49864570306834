import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Space,
  Button,
} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const AddFrom = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [examList, setExamList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [syllabusList, setSyllabusList] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(data, "line26")
  const formattedData = {
    option_values: [data],
  };

  // console.log(option_values,"line 29")
  // const getCourses = () => {
  //   request({
  //     url: apiPath.listCourse,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       setCourseList(data.data.list.docs);
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //       ShowToast(error, Severty.ERROR);
  //     },
  //   });
  // };
  const getSyllabusOption = () => {
    request({
      url: apiPath.syllabusOption,
      method: "GET",
      onSuccess: (data) => {
        console.log(data?.data?.data, "line 44");
        setSyllabusList(data?.data?.data);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  // const getExams = () => {
  //   request({
  //     url: apiPath.listExam,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       setExamList(data.data.list.docs);
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //       ShowToast(error, Severty.ERROR);
  //     },
  //   });
  // };

  useEffect(() => {
    getSyllabusOption();
    // getCourses();
    // getExams();
    if (!data) return;
    console.log(data, "data");


    // form.setFieldsValue({
    //   ...formattedData,
    //   syllabus_option_id: formattedData?.option_values[0].syllabus_option_id._id
    // });

    const formattedDataNew = {
      option_values: [
        {
          title: data.title || "",
          code: data.code || "",
          tool_tip: data.tool_tip || "",
          syllabus_option_id: data?.syllabus_option_id?._id || "",
          duration: data.duration || "",
          weighting: data.weighting || "",
          syllabus_date: data.syllabus_date || "",
          end_syllabus_date: data.end_syllabus_date || "",
        },
      ],
      syllabus_option_id: formattedData?.option_values?.[0]?.syllabus_option_id?._id
    };

    console.log(formattedDataNew, "forformattedDataNewmattedData")
    // Set values in form
    form.setFieldsValue(formattedDataNew);

  }, [data]);

  const onCreate = (values) => {
    console.log("Form values:", values);
    const {
      title,
      code,
      tool_tip,
      duration,
      weighting,
      syllabus_option_id,
      syllabus_date,
      end_syllabus_date,
      option_values
    } = values;
    setLoading(true);

    const payload = {};
    payload.tool_tip = tool_tip;
    payload.duration = duration ? duration : '';
    payload.title = title;
    payload.code = code;
    payload.weighting = weighting ? weighting : '';
    payload.syllabus_option_id = syllabus_option_id;
    payload.syllabus_date = '2024-11-23';
    payload.end_syllabus_date = '2024-11-23';
    payload.option_values = option_values;




    request({
      url: `${data ? api.add + "/" + data._id : api.add}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={800}
      title={`${data ? "Update " + section : "Select " + section}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <Form
        id="create"
        form={form}
        initialValues={{
          option_values: [{ syllabus_date: '2024-11-23', end_syllabus_date: '2024-11-23', duration: '', tool_tip: '', code: '', title: '' }],
        }}
        // initialValues={{ option_values: ["syllabus_option_id"] }}
        onFinish={onCreate}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={24} sm={12}>
            <Form.Item

              label={`Select Syllabus`}
              name={"syllabus_option_id"}
              rules={[
                {
                  required: true,
                  message: "Missing Course Selection",
                },
              ]}
            >
              <Select placeholder="Select Syllabus">
                {syllabusList.length > 0
                  ? syllabusList.map((item, index) => (
                    <option
                      key={item._id}
                      value={item._id}
                    >
                      <span className="cap">
                        {item.syllabus_id.name + " - " + item.option_code}
                      </span>
                    </option>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[12, 0]}>
            <Col span={24}>

              <Form.List name="option_values" className="mt-2">
                {(fields, { add, remove }) => (
                  <>
                    <Row gutter={[16, 16]}>
                      {fields.map((field, index) => (
                        <Col
                          span={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={12}
                          key={field.key}
                        >
                          <Space align="baseline" className="gap-cls">
                            <div
                              className=""
                              style={{ display: "flex", gap: "8px", flexDirection: "column" }}
                            >


                              <Col span={24} sm={12}>
                                <Form.Item
                                  {...field}
                                  label={`Title`}
                                  name={[field.name, "title"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Please enter the syllabus name!`,
                                    },
                                    {
                                      max: 200,
                                      message:
                                        "Syllabus Name should not contain more then 200 characters!",
                                    },
                                    {
                                      min: 2,
                                      message:
                                        "Syllabus Name should contain atleast 2 characters!",
                                    },
                                  ]}
                                  normalize={(value) => value.trimStart()}
                                >
                                  <Input
                                    autoComplete="off"
                                    placeholder={`Enter Syllabus Name`}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={24} sm={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "code"]}
                                  label={`Code`}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Please enter the syllabus Code!`,
                                    },
                                    {
                                      max: 7,
                                      message:
                                        "Syllabus Code should not contain more then 7 characters!",
                                    },
                                    {
                                      min: 1,
                                      message:
                                        "Syllabus Name should contain at least 1 characters!",
                                    },
                                  ]}
                                  normalize={(value) => value.trimStart()}
                                >
                                  <Input
                                    autoComplete="off"
                                    placeholder={`Enter Syllabus Code`}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24} sm={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "tool_tip"]}
                                  label={`Tool tip`}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Please enter the tool tip!`,
                                    },
                                    {
                                      max: 50,
                                      message:
                                        "Tool tip must be under 50 words!",
                                    },
                                    {
                                      min: 2,
                                      message:
                                        "Tool tip should contain at least 2 characters!",
                                    },
                                  ]}
                                  normalize={(value) => value.trimStart()}
                                >
                                  <Input
                                    autoComplete="off"
                                    placeholder={`Enter tool tip`}
                                  />
                                </Form.Item>
                              </Col>

                              {/* <Col span={24} sm={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "duration"]}
                                  label={`Duration`}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Please enter the syllabus Duration!`,
                                    },
                                    {
                                      max: 10,
                                      message:
                                        "Syllabus Duration should not exceed 30min!",
                                    },
                                  ]}
                                  normalize={(value) => value.trimStart()}
                                >
                                  <Input
                                    autoComplete="off"
                                    placeholder={`Enter Syllabus Duration`}
                                  />
                                </Form.Item>
                              </Col> */}

                              {/* <Col span={24} sm={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "weighting"]}
                                  label={`Weightage`}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Please enter the syllabus weightage!`,
                                    },
                                    {
                                      max: 10,
                                      message:
                                        "Syllabus Duration should be like 30%,40%,50%!",
                                    },
                                  ]}
                                  normalize={(value) => value.trimStart()}
                                >
                                  <Input
                                    autoComplete="off"
                                    placeholder={`Enter Syllabus weightage`}
                                  />
                                </Form.Item>
                              </Col> */}

                              {/* <Col span={24} sm={12}>
                                <Form.Item
                                  className="mb-0"
                                  label={`Start Date`}
                                  {...field}
                                  name={[field.name, "syllabus_date"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Date Required",
                                    },
                                    {
                                      validator: (_, value, callback) => {
                                        const currentDate = value;

                                        // Get all the dates in the form
                                        const formValues = form.getFieldValue("option_values");
                                        const allDates = formValues.map(
                                          (item) => item.syllabus_date
                                        );

                                        // Check if the current date exists more than once in the array
                                        const dateCount = allDates.filter(
                                          (date) => date === currentDate
                                        ).length;

                                        if (dateCount > 1) {
                                          callback("This date is already selected in another field.");
                                        } else {
                                          callback();
                                        }
                                      },
                                    },
                                  ]}
                                  normalize={(value) => value.trimStart()}
                                >
                                 
                                  <Input
                                    type="date"
                                    name="syllabus_date"
                                    placeholder="Enter  Date"
                                  />
                                </Form.Item>
                              </Col> */}
{/* 
                              <Col span={24} sm={12}>
                                <Form.Item
                                  className="mb-0"
                                  label={`End Date`}
                                  {...field}
                                  name={[field.name, "end_syllabus_date"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "End Date Required",
                                    },
                                    {
                                      validator: (_, value, callback) => {
                                        const currentDate = value;

                                        // Get all the dates in the form
                                        const formValues = form.getFieldValue("option_values");
                                        const allDates = formValues.map(
                                          (item) => item.end_syllabus_date
                                        );

                                        // Check if the current date exists more than once in the array
                                        const dateCount = allDates.filter(
                                          (date) => date === currentDate
                                        ).length;

                                        if (dateCount > 1) {
                                          callback("This date is already selected in another field.");
                                        } else {
                                          callback();
                                        }
                                      },
                                    },
                                  ]}
                                  normalize={(value) => value.trimStart()}
                                >
                                
                                  <Input
                                    type="date"
                                    name="end_syllabus_date"
                                    placeholder="Enter End  Date"
                                  />
                                </Form.Item>
                              </Col> */}

                              {index > 0 && ( // Only show remove button if there's more than one item
                                <div
                                  className="minus-wrap"
                                  style={{ marginTop: "22px" }}
                                >
                                  <MinusCircleOutlined
                                    onClick={() => remove(field.name)}
                                    style={{
                                      borderRadius: "8px",
                                      margin: "0",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </Space>
                        </Col>
                      ))}

                      {!data ?
                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                          <Form.Item label=" " className="mt-2">
                            <Button
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </Col>
                        : ""}

                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddFrom;
