import React, { useState } from 'react';
import { Button, Modal, Form, Input, Card } from 'antd';
import apiPath from '../constants/apiPath';
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import encryptDecrypt from "../helper/encryptDecrypt";
import { useParams } from 'react-router';

const PasswordChange = () => {

  const [passwordLoading, setPasswordLoading] = useState(false)
  const { request } = useRequest()
  const [visible, setVisible] = useState(false);

  const { email } = useParams();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = (values) => {
    const { password } = values
    setPasswordLoading(true)

    request({
      url: apiPath.resetPassword,
      method: 'POST',
      data: { password, email },
      onSuccess: (data) => {
        setVisible(false);
        setPasswordLoading(false)
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (error) => {
        setPasswordLoading(false)
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  // return (
  //   <div>
  //     <Button className='m-auto' type="primary" onClick={showModal}>
  //       Change Password
  //     </Button>

  //     <Modal
  //       title="Change Password"
  //       open={visible}
  //       okButtonProps={{
  //         form: 'reset-password',
  //         htmlType: 'submit',
  //         loading: passwordLoading
  //       }}
  //       onCancel={handleCancel}
  //       footer={null}
  //     >
  //       <Form
  //         name="passwordForm"
  //         onFinish={handleSubmit}
  //         layout="vertical"
  //       >
  //         <Form.Item
  //           name="password"
  //           label="New Password"
  //           rules={[
  //             {
  //               required: true,
  //               message: 'Please input the new password!',
  //             },
  //           ]}
  //         >
  //           <Input.Password />
  //         </Form.Item>

  //         <Form.Item>
  //           <Button type="primary" htmlType="submit">
  //             Update Password
  //           </Button>
  //         </Form.Item>
  //       </Form>
  //     </Modal>
  //   </div>
  // );

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card style={{ width: '400px', textAlign: 'center', padding: '20px' }}>
        <h2>Email Verified Successfully</h2>
        <p>You can update your password by clicking on the below button:</p>
        <Button type="primary" onClick={showModal}>
          Change Password
        </Button>

        <Modal
          title="Change Password"
          open={visible}
          okButtonProps={{
            form: 'passwordForm',
            htmlType: 'submit',
            loading: passwordLoading
          }}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            id="passwordForm"
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: 'Please input the new password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </div>
  )

};

export default PasswordChange;
