
import { useState, useEffect, useContext } from "react";
import { Row, message, Col, Breadcrumb, Badge, Dropdown, Button, Card, Input, Image, Form, Modal, Upload as UploadAntd, TextArea } from "antd";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";
import { KeyOutlined, LogoutOutlined, UserOutlined, SettingOutlined, QuestionCircleFilled } from "@ant-design/icons";
import apiPath from "../../constants/apiPath";
import { ShowToast, Severty } from "../../helper/toast";
import { NavLink, Link } from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import { uploadFile } from 'react-s3';
import notfound from "../../assets/images/not_found.png";
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
const s3Config = {
  region: 'ap-south-1',
  credentials: {
    accessKeyId: "B8ESG93XA59WD5N2H2SJ",
    secretAccessKey: "5HLMmSrAfEWuPByapd3lVMZBaynTNkfAKlTZZn0L",
  },
  endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080",
  forcePathStyle: true,
};
const s3Client = new S3Client(s3Config);
const { confirm } = Modal;


const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ name, onPress }) {

  useEffect(() => window.scrollTo(0, 0));
  const [visible, setVisible] = useState(false);

  const [profile, setProfile] = useState({});
  const [selected, setSelected] = useState();
  const [profileVisible, setProfileVisible] = useState(false);

  const [appSetting, setAppSetting] = useState({});
  const [breadcrumb, setBreadcrumb] = useState(name?.split('/') ?? []);
  const [appSettingSelected, setAppSettingSelected] = useState();
  const [appSettingVisible, setAppSettingVisible] = useState(false);

  const { request } = useRequest()
  const [refresh, setRefresh] = useState(false)
  const { logout } = useContext(AuthContext)
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const items = [
    {
      label: 'Edit Profile',
      key: '1',
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: 'Change Password',
      key: '2',
      icon: <KeyOutlined />,
      danger: true,
    },
    // {
    //   label: 'App Setting',
    //   key: '4',
    //   icon: <SettingOutlined />,
    //   danger: true,
    // },
    {
      label: 'Logout',
      key: '3',
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];

  const showDeleteConfirm = (record) => {
    setTimeout(() => {
      confirm({
        okText: 'Logout',
        okType: 'danger',
        icon: <QuestionCircleFilled />,
        cancelText: 'Cancel',
        content: <Button >Are you sure you want to logout ?</Button>,
        onOk() {
          logout();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const handleMenuClick = (e) => {
    if (e.key == 2) {
      setVisible(true)
    }
    if (e.key == 1) {
      setProfileVisible(true)
    }
    if (e.key == 4) {
      setAppSettingVisible(true)
    }
    if (e.key == 3) {
      showDeleteConfirm()
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onCreate = (values) => {
    const { old_password, new_password } = values
    const payload = {}
    if (!old_password || !new_password) return ShowToast('Please enter password ', Severty.ERROR)
    setLoading(true)
    payload.new_password = new_password
    payload.old_password = old_password
    request({
      url: apiPath.changePassword,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          setVisible(false)
          logout()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  useEffect(() => {

    setBreadcrumb(name?.split('/') ?? [])
  }, [name])
  useEffect(() => {
    request({
      url: apiPath.profile,
      method: 'GET',
      onSuccess: (data) => {
        setProfile(data.data)
        setSelected(data.data)
      },
    })
    request({
      url: apiPath.getAppSetting,
      method: 'GET',
      onSuccess: (data) => {
        setAppSetting(data.data)
        setAppSettingSelected(data.data)
      },
    })
  }, [refresh])

  return (
    <>

      <Row gutter={[24, 0]}>
        <Col span={24} xs={18} md={18} className="breadcrumbMain">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Home</NavLink>
            </Breadcrumb.Item>

            {
              breadcrumb.map((item, index) =>
                <Breadcrumb.Item key={item} style={{ textTransform: "capitalize" }}>
                  {/* <NavLink to="/">{item}</NavLink> */}
                  {item}
                </Breadcrumb.Item>
              )
            }


          </Breadcrumb>

        </Col>

        <Col span={24} xs={24} sm={6} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <div className="profileDropdownMain">
            <Dropdown menu={menuProps}>
              <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                <div className="userName">{profile ? profile.name : "Administrator"}</div>
                <div className="userEmail">{profile ? profile.email : "admin@noreply.com"} </div>
              </Button>
            </Dropdown>
            <Image
              src={profile ? profile.image : 'noimage'}
            />
          </div>
        </Col>
      </Row>

      {profileVisible && <EditProfile show={profileVisible} hide={() => { setProfileVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {appSettingVisible && <AppSetting show={appSettingVisible} hide={() => { setAppSettingVisible(false) }} data={appSettingSelected} refresh={() => setRefresh(prev => !prev)} />}

      <Modal
        visible={visible}
        title="Change password"
        okText="Ok"
        onCancel={() => {
          setVisible(false);
        }}
        onOk={handleCreate}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Old Password"
            name="old_password"
            hasFeedback
            rules={[
              { required: true, message: "Please enter the old password!" },
            ]}
          >
            <Input.Password maxLength={50} placeholder="Enter your old password" />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            dependencies={['old_password']}
            hasFeedback
            rules={[
              { required: true, message: "Please enter the new password!" },
              { min: 6, message: "Please enter the atleast 6 charcaters!" },
              // { pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/), message: "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('old_password') === value) {
                    return Promise.reject(new Error('Old password & new password must be different!'));
                  }
                  return Promise.resolve();

                },
              }),
            ]}
          >
            <Input.Password maxLength={50} placeholder="Enter your new password" />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirm_new_password"
            dependencies={['new_password']}
            hasFeedback
            rules={[
              { required: true, message: "Please enter the confirm password!" },
              { min: 6, message: "Please enter the atleast 6 charcaters!" },
              // { pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/), message: "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('New password and Confirm password do not match!'));
                },
              }),
            ]}
          >
            <Input.Password maxLength={50} placeholder="Enter your confirm password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const EditProfile = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
      message.error("File format is not correct")
      return false
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(`Image must be smaller than 5 MB!`)
      return false
    }
    return true
  }


  const handleChangeOld = async (event) => {
    const { file } = event
    setFile([file])
    uploadFile(file, s3Config("profile"))
      .then(data => {
        const fileData = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: data.location,
          thumbUrl: data.location,
        }
        setFile([fileData])
        console.log(data)
      }
      )
      .catch(err => console.error(err))
  };

  const uploadFileToS3 = async (file, bucketName) => {
    const key = "examconnect/profile" + + "/" + file.name
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
    };

    const upload = new Upload({
      client: s3Client,
      params: params,
    });

    try {
      const data = await upload.done();
      console.log(data)
      return data;
    } catch (err) {
      throw err;
    }
  };

  const handleChange = async (event) => {
    const { file } = event;
    setFile([file]);
    try {
      const data = await uploadFileToS3(file, 'invent-colab-obj-bucket');
      const fileData = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: data.Location,
        thumbUrl: data.Location,
      };
      setFile([fileData])
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })
    //setFile([data.image]);
    if (data.image != undefined) {
      setImage(data.image)
    } else {
      setImage([notfound])
    }
  }, [data])


  const onEditProfile = (values) => {
    const { email, name } = values
    console.log(file.length <= 0 || !image);
    if (file.length <= 0 && !image) return ShowToast('Please select the profile Image ', Severty.ERROR)
    const payload = {}
    setLoading(true)
    payload.email = email
    payload.name = name
    payload.image = file.length > 0 ? file[0].url : null
    request({
      url: apiPath.updateProfile,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Edit Profile' : ''}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onEditProfile} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item label="Name" name="name"
              rules={[
                { required: true, message: "Please enter the name!" },
                { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "Only Alphabetic Characters Allowed" }
              ]}
            >
              <Input placeholder="Enter Name"  maxLength={100}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email Address" name="email"
              rules={
                [
                  {
                    type: 'email', message: 'The input is not valid E-mail!',
                  },
                  { required: true, message: "Please enter the email!" }
                ]}
            >
              <Input placeholder="Enter Email Address" disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Upload Profile" name="image" rules={[{ required: file.length > 0 ? false : true, message: "Please enter the profile image!" }]} >
              <UploadAntd
                listType="picture"
                maxCount={1}
                beforeUpload={beforeUpload}
                customRequest={handleChange}
                onRemove={(e) => setFile([])}
                fileList={file}
              >
                {file && file.length > 0 ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
              </UploadAntd>

              {image && <div className="mt-3"> <Image width={300} src={image}></Image> </div>}

            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const AppSetting = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data });
  }, [data])


  const onAppSetting = (values) => {
    setLoading(true)
    request({
      url: apiPath.updateAppSetting,
      method: 'POST',
      data: values,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      width={1200}
      visible={show}
      title={`${data ? 'Update App Setting' : ''}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >

      <Form id="create" form={form} onFinish={onAppSetting} layout="vertical">
        <Row gutter={{ xs: [0, 16], md: [16, 0] }}>

          <Col span={24} md={12}>
            <Card title="Android Details">
              <Col span={24}>
                <Form.Item label="App Store URL" name="app_store_url"
                  rules={[
                    { required: true, message: "Please enter the app store URL!" },
                  ]}
                >
                  <Input placeholder="Enter App Store URL" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Version" name="android_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input placeholder="Enter Android Version" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Share Content" name="android_share_content"
                  rules={[
                    { required: true, message: "Please enter the share content!" },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share Android Content"
                  />

                </Form.Item>
              </Col>
            </Card>
          </Col>


          <Col span={24} md={12}>
            <Card title="IOS Details">

              <Col span={24} className="">
                <Form.Item label="Play Store URL" name="play_store_url"
                  rules={[
                    { required: true, message: "Please enter the play store URL!" },
                  ]}
                >
                  <Input placeholder="Enter Play Store URL" />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item label="Version" name="ios_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input placeholder="Enter IOS Version" />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item label="Share Content" name="ios_share_content"
                  rules={[
                    { required: true, message: "Please enter the share content!" },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share IOS Content"
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>

        </Row>

      </Form>
    </Modal>
  )
}

export default Header;
