import { Row, Col, Modal, Form, Input, Select, DatePicker, Space, Button } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";


const AddFrom = ({ section, api, show, hide, data, refresh }) => {

    const [form] = Form.useForm();
    const { request } = useRequest()
    const [examList, setExamList] = useState([])
    const [courseList, setCourseList] = useState([])
    const [loading, setLoading] = useState(false)

    const getCourses = () => {
        request({
            url: apiPath.listCourse,
            method: 'GET',
            onSuccess: (data) => {
                setCourseList(data.data.list.docs);
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    };

    const getExams = () => {
        request({
            url: apiPath.listExam,
            method: 'GET',
            onSuccess: (data) => {
                setExamList(data.data.list.docs);
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    };

    useEffect(() => {
        getCourses();
        getExams();
        if (!data) return;
        
        form.setFieldsValue({ ...data, exam_id: data.exam_id?._id, course_id: data.course_id?._id })
    }, [data])


    const onCreate = (values) => {

        const { name, description, exam_id, course_id, option_values } = values
        setLoading(true)

        const payload = {}
        payload.name = name;
        payload.description = description;
        payload.exam_id = exam_id;
        payload.course_id = course_id;
        payload.option_values = option_values;

        request({
            url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    hide()
                    refresh()
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    return (
        <Modal
            open={show}
            width={800}
            title={`${data ? 'Update ' + section : 'Create a New ' + section}`}
            okText="Ok"
            onCancel={hide}
            okButtonProps={{
                form: 'create',
                htmlType: 'submit',
                loading: loading,
            }}
        >
            <Form id="create" form={form} initialValues={{ option_values: [null] }} onFinish={onCreate} layout="vertical">
                <Row gutter={[16, 16]}>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Syllabus Option Code`} name="option_code"
                            rules={[
                                { required: true, message: `Please enter the syllabus option code!` },
                                { max: 10, message: "Syllabus Option Code should not contain more then 10 characters!" },
                                { min: 1, message: "Syllabus Option Code should contain atleast 1 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            <Input autoComplete="off" placeholder={`Enter Syllabus Option Code`} />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Syllabus Option Info`} name="option_info"
                            rules={[
                                { required: true, message: `Please enter the syllabus Option Info!` },
                                { max: 500, message: "Syllabus Option Info should not contain more then 500 characters!" },
                                { min: 2, message: "Syllabus Option Info should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            <Input autoComplete="off" placeholder={`Enter Syllabus Option Info`} />
                        </Form.Item>
                    </Col>

                </Row>

            </Form>
        </Modal>
    )
}

export default AddFrom;

