import { Row, Col, Modal, Form, Input, Select, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";


const AddFrom = ({ section, api, show, hide, data, refresh }) => {

    const [form] = Form.useForm();
    const { request } = useRequest()
    const [centerList, setCenterList] = useState([])
    const [examList, setExamList] = useState([])
    const [loading, setLoading] = useState(false)

    const getCenters = () => {
        request({
            url: apiPath.listCenter,
            method: 'GET',
            onSuccess: (data) => {
                setCenterList(data.data.list.docs);
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    };

    const getExams = () => {
        request({
            url: apiPath.listExam,
            method: 'GET',
            onSuccess: (data) => {
                setExamList(data.data.list.docs);
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    };

    useEffect(() => {
        getExams();
        getCenters();
        if (!data) return;

        form.setFieldsValue({ ...data, center_id : data.center_id?._id, exam_id: data.exam_id?._id })
    }, [data])


    const onCreate = (values) => {

        const { center_id, exam_id, booking_start_date, booking_end_date ,booking_late_date , booking_super_late_date} = values
        setLoading(true)

        const payload = {}
        payload.booking_start_date = booking_start_date;
        payload.booking_end_date = booking_end_date;
        payload.booking_late_date = booking_late_date;
        payload.booking_super_late_date = booking_super_late_date;
        payload.exam_id = exam_id;
        payload.center_id = center_id;

        request({
            url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    hide()
                    refresh()
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    return (


        <Modal
            open={show}
            width={800}
            title={`${data ? 'Update ' + section : 'Create a New ' + section}`}
            okText="Ok"
            onCancel={hide}
            okButtonProps={{
                form: 'create',
                htmlType: 'submit',
                loading: loading,
            }}
        >
            <Form id="create" form={form} onFinish={onCreate} layout="vertical">
                <Row gutter={[16, 16]}>

                    <Col span={24} sm={12}>
                        <Form.Item className="category-elt mb-0"
                            name="exam_id"
                            label="Select Exam"
                            rules={[{ required: true, message: 'Missing Exam Selection' }]}
                        >
                            <Select placeholder="Select Exam">
                                {examList.length > 0 ? examList.map((item, index) => (
                                    <option selected={data && data?.exam_id?._id === item._id} key={item._id} value={item._id}>
                                        <span className="cap">{item.name}</span>
                                    </option>
                                )) : null}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                        <Form.Item className="category-elt mb-0"
                            name="center_id"
                            label="Select Center"
                            rules={[{ required: true, message: 'Missing Exam Selection' }]}
                        >
                            <Select placeholder="Select Center">
                                {centerList.length > 0 ? centerList.map((item, index) => (
                                    <option selected={data && data?.exam_id?._id === item._id} key={item._id} value={item._id}>
                                        <span className="cap">{item.name}</span>
                                    </option>
                                )) : null}
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* <Col span={24} sm={12}>
                        <Form.Item className="category-elt mb-0"
                            name="center_id"
                            label="Select Centre"
                            rules={[{ required: true, message: 'Missing Centre Selection' }]}
                        >
                            <Select placeholder="Select Centre">
                                {centerList.length > 0 ? centerList.map((item, index) => (
                                    <option selected={data && data.center_id._id === item._id} key={item._id} value={item._id}>
                                        <span className="cap">{item.name}</span>
                                    </option>
                                )) : null}
                            </Select>
                        </Form.Item>
                    </Col> */}

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Booking Start Date`} name="booking_start_date"
                            rules={[
                                { required: true, message: "Booking Start Date must be provided!" },
                                { max: 200, message: "Booking Start Date should not contain more then 200 characters!" },
                                { min: 2, message: "Booking Start Date should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            {/* <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} onChange={(e) => {
                                if (e != null) {
                                    // setEndDate(moment(e._d).format("YYYY-MM-DD"))
                                }
                            }} /> */}
                            <Input type="date" name="booking_start_date" placeholder="Enter Booking Start Date" />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Booking End Date`} name="booking_end_date"
                            rules={[
                                { required: true, message: "Booking End Date must be provided!" },
                                { max: 200, message: "Booking End Date should not contain more then 200 characters!" },
                                { min: 2, message: "Booking End Date should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            {/* <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} onChange={(e) => {
                                if (e != null) {
                                    // setEndDate(moment(e._d).format("YYYY-MM-DD"))
                                }
                            }} /> */}
                            <Input type="date" name="end_date" placeholder="Enter Booking End Date" />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                        <Form.Item
                            className="mb-0"
                            label={`Late Booking Date`}
                            name="booking_late_date"
                            rules={[
                                { required: true, message: `Please enter the late booking date!` },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || new Date(value) >= new Date(getFieldValue('booking_end_date'))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Late booking date must be after the booking end date!')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                type="date"
                                autoComplete="off"
                                placeholder={`Enter Late Booking Date`}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Super Late Booking Date`}
              name="booking_super_late_date"
              rules={[
                {
                  required: true,
                  message: `Please enter the super late booking date!`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      new Date(value) >=
                        new Date(getFieldValue("booking_late_date"))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Super late booking date must be after the booking late date!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                type="date"
                autoComplete="off"
                placeholder={`Enter Super Late Booking Date`}
              />
            </Form.Item>
          </Col>
                </Row>

            </Form>
        </Modal>
    )
}

export default AddFrom;

