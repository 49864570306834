const shortLang = {
    en: "En",
    es: "Es",
    de: "De",
    fr: "Fr"
}

const longLang = {
    en: "English",
    es: "Spanish",
    de: "German",
    fr: "French"
}

export { shortLang, longLang };