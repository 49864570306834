import { Row, Col, Modal, Form, Input, Select, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";



const AddFrom = ({ section, api, show, hide, data, refresh }) => {

    const [form] = Form.useForm();
    const { request } = useRequest()
    const [organizationList, setOrganizationList] = useState([])
    const [loading, setLoading] = useState(false)

    const getOrganizations = () => {
        request({
            url: apiPath.listOrganizer,
            method: 'GET',
            onSuccess: (data) => {
                setOrganizationList(data.data.list.docs);
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    };

    useEffect(() => {
        getOrganizations();
        if (!data) return;

        form.setFieldsValue({ ...data, organizer_id: data.organizer_id?._id })
    }, [data])


    const onCreate = (values) => {

        const { name, organizer_id, exam_code, start_date, end_date } = values
        setLoading(true)

        const payload = {}
        payload.name = name;
        payload.start_date = start_date;
        payload.end_date = end_date;
        payload.exam_code = exam_code;
        payload.organizer_id = organizer_id;

        request({
            url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    hide()
                    refresh()
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    return (
        <Modal
            open={show}
            width={800}
            title={`${data ? 'Update ' + section : 'Create a New ' + section}`}
            okText="Ok"
            onCancel={hide}
            okButtonProps={{
                form: 'create',
                htmlType: 'submit',
                loading: loading,
            }}
        >
            <Form id="create" form={form} onFinish={onCreate} layout="vertical">
                <Row gutter={[16, 16]}>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Name`} name="name"
                            rules={[
                                { required: true, message: `Please enter the name!` },
                                { max: 200, message: "Name should not contain more then 200 characters!" },
                                { min: 2, message: "Name should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            <Input autoComplete="off" placeholder={`Enter Name`} />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Exam Code`} name="exam_code"
                            rules={[
                                { required: true, message: "Exam Code must be provided!" },
                                { max: 200, message: "Exam Code should not contain more then 200 characters!" },
                                { min: 2, message: "Exam Code should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            <Input autoComplete="off" placeholder={`Enter Exam Code`} />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Exam Start Date`} name="start_date"
                            rules={[
                                { required: true, message: "Exam Start Date must be provided!" },
                                { max: 200, message: "Exam Start Date should not contain more then 200 characters!" },
                                { min: 2, message: "Exam Start Date should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            {/* <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} onChange={(e) => {
                                if (e != null) {
                                    // setEndDate(moment(e._d).format("YYYY-MM-DD"))
                                }
                            }} /> */}
                            <Input type="date" name="start_date" placeholder="Enter Exam Start Date" />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="mb-0" label={`Exam End Date`} name="end_date"
                            rules={[
                                { required: true, message: "Exam End Date must be provided!" },
                                { max: 200, message: "Exam End Date should not contain more then 200 characters!" },
                                { min: 2, message: "Exam End Date should contain atleast 2 characters!" },
                            ]}
                            normalize={value => value.trimStart()}
                        >
                            {/* <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} onChange={(e) => {
                                if (e != null) {
                                    // setEndDate(moment(e._d).format("YYYY-MM-DD"))
                                }
                            }} /> */}
                            <Input type="date" name="end_date" placeholder="Enter Exam End Date" />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item className="category-elt mb-0"
                            name="organizer_id"
                            label="Select Organizer"
                            rules={[{ required: true, message: 'Missing Organizer Selection' }]}
                        >
                            <Select placeholder="Select Organizer">
                                {organizationList && organizationList && organizationList.length > 0 ? organizationList.map((item, index) => (
                                    <option selected={data && data.organizer_id._id === item._id} key={item._id} value={item._id}>
                                        <span className="cap">{item.name}</span>
                                    </option>
                                )) : null}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>
        </Modal>
    )
}

export default AddFrom;

