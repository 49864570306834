import { Card, Col, Row, Collapse, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { ShowToast, Severty } from "../../helper/toast";
import { PlusOutlined } from "@ant-design/icons";
import AddEditSyllabusOpt from "./Component/AddEditSyllabusOpt";

const { Panel } = Collapse;
const ViewAllDetails = () => {
  const params = useParams();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [syllabusOptData, setSyllabusOptData] = useState({});
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const centerId = params.id
  const getDetails = (id) => {
    if (id) {
      request({
        url: apiPath.getCourseDetails + `/${id}`,
        method: "GET",
        onSuccess: (apiData) => {
          setData(apiData.data.data);
        },
        onError: (error) => {
          setLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    }
  };
  useEffect(() => {
    getDetails(params.exam_id);
  }, [params,refresh]);
  return (
    <div>
      <div className="tabled categoryService">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={"Exam Details"}
            >
              <div className="table-responsive customPagination">
                <Row className="text-center font-bold">
                  <Col span={4}>Name</Col>
                  <Col span={3}>Centre</Col>
                  <Col span={3}>Booked/Seats</Col>
                  <Col span={3}>Fee</Col>
                  <Col span={3}>Late</Col>
                  <Col span={4}>SuperLate</Col>
                  <Col span={4}>Action</Col>
                </Row>
                {data.map((exam) => {
                  const { courseDetails, name, _id } = exam;
                  return (
                    <Row key={_id} className="fa-2x">
                      <Col span={1}></Col>
                      <Col span={23}>
                        <Collapse>
                          <Panel header={name} key={_id}>
                            {courseDetails.map((course) => (
                              <Collapse key={course._id} className="mb-3">
                                <Panel header={course.name} key={course._id}>
                                  {course.syllabusesDetails.length > 0 && course.syllabusesDetails[0].syllabusesOptionsDetails.length > 0 && course.syllabusesDetails.map((syllabus) => {
                                    const { syllabusesOptionsDetails } =
                                      syllabus;
                                    return (
                                      <Collapse
                                        key={syllabus._id}
                                        className="mb-3"
                                      >
                                        <Panel
                                          header={`${syllabus.name} : ${syllabus?.syllabus_code || "N/A"
                                            }`}
                                          className="cap"
                                          key={syllabus._id}
                                        >
                                          <ul className="list-group">
                                            {syllabusesOptionsDetails.map(
                                              (syllabusOpt) => (
                                                <li
                                                  key={syllabusOpt._id}
                                                  className="list-group-item"
                                                >
                                                  <Row>
                                                    <Col span={6}>
                                                      <p>
                                                        {syllabusOpt?.option_code ||
                                                          "None"}
                                                      </p>
                                                    </Col>
                                                    <Col span={6} offset={12}>
                                                      <Button
                                                        type="primary"
                                                        shape="circle"
                                                        icon={<PlusOutlined />}
                                                        size={"small"}
                                                        onClick={() => {
                                                          setShow(!show);
                                                          setSyllabusOptData({
                                                            ...syllabusOptData,
                                                            courseName:
                                                              course.name,
                                                            syllabusName:
                                                              syllabus.name,
                                                            syllabusOptName:
                                                              syllabusOpt?.option_code ||
                                                              "None",
                                                            examId: _id || "",
                                                            examConductsId:
                                                              exam?.examConducts
                                                                ?._id || "",
                                                            centerId:
                                                              centerId ? centerId : "",
                                                            courseId:
                                                              course?._id || "",
                                                            syllabusId:
                                                              syllabusOpt?.syllabus_id ||
                                                              "",
                                                            syllabusOptionId:
                                                              syllabusOpt._id,
                                                          });
                                                        }}
                                                      />
                                                    </Col>
                                                  </Row>
                                                  {/* <p>{syllabus.description}</p> */}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </Panel>
                                      </Collapse>
                                    );
                                  })}
                                </Panel>
                              </Collapse>
                            ))}
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <AddEditSyllabusOpt
        show={show}
        hide={() => {
          setSyllabusOptData({})
          setShow(false)
        } }
        data={syllabusOptData}
        refresh={()=> setRefresh(prev => !prev)}
      />
    </div>
  );
};

export default ViewAllDetails;
