import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import { AppContextProvider } from "./context/AppContext";
import React, { useContext, Suspense } from "react";
import Home from "./pages/Auth/Home";
import Profile from "./pages/Auth/Profile";
import SignIn from "./pages/Auth/SignIn";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Error from "./pages/Error";

import Guardian from "./pages/User/Guardian/Index";
import GuardianView from "./pages/User/Guardian/View";
import Student from "./pages/User/Student/Index";
import StudentView from "./pages/User/Student/Index";
import Activity from "./pages/User/Activity";

import Center from "./pages/Center/Index";
import ViewExam from "./pages/Center/ViewExams";
import AddBooking from "./pages/Center/AddBooking";
import Course from "./pages/Course/Index";
import Exam from "./pages/Exam/Index";

import Event from "./pages/Event/Index";
import AddEvent from "./pages/Event/AddEvent";
import EditEvent from "./pages/Event/EditEvent"
import ViewEvent from "./pages/Event/ViewEvent"


import Preparation from "./pages/ExamPreparation/Index";
import AddPreparation from "./pages/ExamPreparation/AddPreparation";
import EditPreparation from './pages/ExamPreparation/EditPreparation';
import ViewPreparation from './pages/ExamPreparation/ViewPreparation';


import Syllabus from "./pages/Syllabus/Index";
import SyllabusOptions from "./pages/SyllabusOptions/Index";

import ExamConduct from "./pages/ExamConduct/Index";
import AddExam from "./pages/Event/AddEvent";

import Content from "./pages/Content/Index";
import ContentView from "./pages/Content/View";
import ContentEdit from "./pages/Content/Edit";

import SyllabusComponentOption from "./pages/SyllabusComponentOption/Index";


import EmailTemplate from "./pages/EmailTemplate/Index";
import ExamBoard from "./pages/ExamBoard/Index";
import EmailTemplateView from "./pages/EmailTemplate/View";
import EmailTemplateEdit from "./pages/EmailTemplate/Edit";
import Privacy_policy from "./components/PrivacyPolicy";
import PasswordChange from "./components/ResetPassword";
import ViewAllDetails from "./pages/Center/ViewAllDetails";
import Notification from "./pages/Notification";
import ViewNotification from "./pages/ViewNotification";
import OrderManager from "./pages/OrderManager";
import ReturnUrl from "./pages/ReturnUrl";
import CancelUrl from "./pages/CancelUrl";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ViewExamConduct from "./pages/ExamConduct/ViewExamConduct";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/success" element={<ReturnUrl />} />
      <Route path="/cancel" element={<CancelUrl />} />
      <Route
        exact
        path="/content/:slug"
        element={<PrivacyPolicy />}
      />
      {/* <Route path="/" element={<SignIn />} /> */}
      <Route
        exact
        path="/content-privacy-policy"
        element={<Privacy_policy />}
      />
      <Route exact path="/reset-password/:email" element={<PasswordChange />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />

        {/* Guardian Routes */}
        <Route exact path="/guardian" element={<Guardian />} />
        <Route exact path="/guardian/view/:id" element={<GuardianView />} />
        <Route exact path="/user/activity/:id" element={<Activity />} />

        {/* Center Routes */}
        <Route exact path="/centre" element={<Center />} />
        <Route exact path="/centre/:id/view-exams" element={<ViewExam />} />
        <Route
          exact
          path="/centre/:id/view-exams/:exam_id/add"
          element={<ViewAllDetails />}
        />

        {/* Course Routes */}
        <Route exact path="/course" element={<Course />} />

        {/* Exam Routes */}
        <Route exact path="/exam" element={<Exam />} />

        {/* Exam Routes */}
        <Route exact path="/add-exam" element={<AddExam />} />



        {/* Event Routes */}
        <Route exact path="/event" element={<Event />} />
        <Route exact path="/event/add" element={<AddEvent />} />
        <Route exact path="/event/edit/:id" element={<EditEvent />} />
        <Route exact path="/event/view/:id" element={<ViewEvent />} />




        {/* preparation Routes */}
        <Route exact path="/preparation" element={<Preparation />} />
        <Route exact path="/preparation/add" element={<AddPreparation />} />
        <Route exact path="/preparation/add/:id" element={<AddPreparation />} />
        <Route exact path="/preparation/edit/:id" element={<EditPreparation />} />
        <Route exact path="/preparation/view/:id" element={<ViewPreparation />} />



        {/*syllabus option Routes*/}
        <Route exact path="/syllabusOption" element={<SyllabusComponentOption />} />

        {/* Syllabus Routes */}
        <Route exact path="/syllabus" element={<Syllabus />} />
        <Route
          exact
          path="/syllabus/:id/options"
          element={<SyllabusOptions />}
        />

        {/* Exam Conduct Routes */}
        <Route exact path="/exam-conduct" element={<ExamConduct />} />
        <Route exact path="/exam-conduct/view/:id" element={<ViewExamConduct />} />

        {/* Student Routes */}
        <Route exact path="/student" element={<Student />} />
        <Route exact path="/student/view/:id" element={<StudentView />} />
        <Route exact path="/user/activity/:id" element={<Activity />} />

        {/* Content Routes */}
        <Route exact path="/content" element={<Content />} />
        <Route exact path="/content/update/:slug?" element={<ContentEdit />} />
        <Route exact path="/content/view/:slug" element={<ContentView />} />

        {/* EmailTemplate Routes */}
        <Route exact path="/email-template" element={<EmailTemplate />} />
        <Route
          exact
          path="/email-template/update/:id?"
          element={<EmailTemplateEdit />}
        />
        <Route
          exact
          path="/email-template/view/:id"
          element={<EmailTemplateView />}
        />
        <Route
          exact
          path="/notification-manager"
          element={<Notification />}
        />
        <Route
          exact
          path="/view-notification/:id"
          element={<ViewNotification />}
        />

        <Route
          exact
          path="/booking-manager"
          element={<OrderManager />}
        />
        <Route
          exact
          path="/exam-board"
          element={<ExamBoard />}
        />



      </Route>


      <Route path="*" element={<Error />} />
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      {" "}
      <Outlet />{" "}
    </>
  );
};

export default App;
